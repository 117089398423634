/* eslint-disable */
import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import Tooltip from '@material-ui/core/Tooltip';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Drawer from '@material-ui/core/Drawer';
import "../layout/Navbar.css"
import { Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { yellow } from '@material-ui/core/colors';
import WelcomeMessage from '../pages/welcome/Welcome';
import Grid from '@material-ui/core/Grid';
import Maintenance from '../alerts/Maintenance';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginInlineEnd: 7,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  introPaper : {

  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    
  },
}));



const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[800],
    '&:hover': {
      backgroundColor: yellow[900],
    },
  },
}))(Button);

const Navbar = ({ setCorsErrorModalOpen }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err) => (err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch');
  const login = async () => oktaAuth.signInWithRedirect({originalUri : "/menu"});

  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
      <CssBaseline />
      <AppBar style={{ textDecoration: 'none', backgroundColor: "#0d4386",    
 }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton >
          <img src={process.env.PUBLIC_URL + '/CHicon.png'} width="30" height="30" className="d-inline-block align-top" alt=""></img>
        
          <Typography className="navbar-brand p-2">
            CyncHealth 
          </Typography>
          <span className="example-fill-remaining-space"></span>
          <Typography  id="myHeading"variant="h6" fontWeight="fontWeightBold" style={{textShadow: "2px 4px 3px rgba(0,0,0,0.3)"}}>
            Nebraska Medicine - Primary Care First Dashboard
          </Typography>

          <span className="example-fill-remaining-space"></span>
          
          {authState.isAuthenticated && (
            <ColorButton     
              variant="contained"
              color= "primary"
              endIcon={<PersonIcon></PersonIcon>}
              id="logout-button"
              onClick={logout}>Logout
            </ColorButton>
          )}
          {!authState.isPending && !authState.isAuthenticated && (
            <ColorButton     
              variant="contained"
              color= "primary"
              endIcon={<PersonIcon></PersonIcon>}
              id="logout-button"
              onClick={login}>Login
            </ColorButton>
          )}
         
        </Toolbar>

      </AppBar>

   
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }) }
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        {authState.isAuthenticated && (
        <List
         onMouseOver={handleDrawerOpen}
         onMouseLeave={handleDrawerClose}>

            <Link to="/menu" style={{ textDecoration: 'none', color: 'grey' }}>
            <ListItem button > 
            <Tooltip disableFocusListener title="Menu" placement="top-end" arrow>
            <ListItemIcon ><DashboardIcon/></ListItemIcon>
            </Tooltip>
            <ListItemText> Menu</ListItemText>
            </ListItem>
            </Link>

            <Link to="/gapreport" style={{ textDecoration: 'none', color: 'grey' }}>
            <ListItem button> 
            <Tooltip disableFocusListener title="Patients Gap Report" placement="top-end" arrow>
              <ListItemIcon> <AssignmentIndIcon/></ListItemIcon>
              </Tooltip>
              <ListItemText>Patients Gap Report</ListItemText>
            </ListItem>
            </Link>

            <Link to="/providerlevel" style={{ textDecoration: 'none', color: 'grey' }}>
            <ListItem button> 
            <Tooltip disableFocusListener title="Provider Level Performance" placement="top-end" arrow>
              <ListItemIcon> <LocalHospitalIcon/></ListItemIcon>
              </Tooltip>
              <ListItemText> Provider Level </ListItemText>
            </ListItem>
            </Link>

            <Link to="/overall" style={{ textDecoration: 'none', color: 'grey' }}>
            <ListItem button> 
            <Tooltip disableFocusListener title="Overall Performance" placement="top-end" arrow>
              <ListItemIcon> <EqualizerIcon/></ListItemIcon>
              </Tooltip>
              <ListItemText> Overall Performance</ListItemText>
            </ListItem>
            </Link>
            <Divider />
        </List>
        )}
    
      </Drawer>


      <main className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}>
         <div>{!authState.isPending && !authState.isAuthenticated && (
         
          <WelcomeMessage/>)}</div>
           {/* <div style={{paddingLeft:"16px"}}>{!authState.isPending && !authState.isAuthenticated && (
         
         <Maintenance/>)}</div> */}

          {/* <div className="footer">
          <Grid container spacing={1} className="mb-3">
            <Grid item xs={5} className="text-center">
             © 2021 All Rights Reserved.
            </Grid>
            <Grid item xs={3} className="text-center">
            Powered by: <a href="https://cynchealth.org/" target="_blank" style={{ textDecoration: 'none', color:'#3F51B5'}} > CyncHealth</a>
            </Grid>
            <Grid item xs={3} className="text-center">
            <a href="https://cynchealth.org/website-privacy/" target="_blank" style={{ textDecoration: 'none', color:'#3F51B5'}}> Privacy Policy</a>
            </Grid>
          </Grid>
           </div> */}
      </main>
    </div>
    </>
  );
};
export default Navbar;
