/* eslint-disable */
import React, {useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import TableauReport from 'tableau-react';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginTop: 75,
    background: '#f5f7fa',
    padding: theme.spacing(1),
    width:"95%",
  },
  viewStyling: {
    padding: 0,
  }
}));

function TableauViews(props) {
    const { authState } = useOktaAuth();
    const [tokenResponse, setTokenResponse] = useState({ token: '' });
    const classes = useStyles();
    const getOptionsForView = (view) => {
        return {
          height: view.viewHeight,
          width: '100%',
          hideTabs: false,
          hideToolbar: true,
        }
      };
    
useEffect(()=>{
    async function fetchData() {
        const accessTokenResponse = authState.accessToken
        const bearerToken = accessTokenResponse?.accessToken;  
        const options = {
          headers: {
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${bearerToken}`,
          },
        }

        const url = `https://blzoz23yv5.execute-api.us-east-1.amazonaws.com/prod/auth/tableau`;
        const response = await axios(url, options);
        setTokenResponse(response.data);
      }
    
      fetchData();
    }, [authState]);
    return (
      <div className={classes.content}>
        <Paper elevation={2} className={classes.viewStyling}>
          {tokenResponse.token &&
            <TableauReport
                url={`https://insights.cynchealth.org/trusted/${tokenResponse.token}/t/PCF/views/${props.viewPath}`}
                options={getOptionsForView(props)}
            />
          }
        </Paper>
      </div>
    )
}
export default TableauViews;