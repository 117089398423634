/* eslint-disable */
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import config from './config';
import Navbar from './components/layout/Navbar';
import CorsErrorModal from './CorsErrorModal';
import Menu from './components/pages/menu'
import TableauViews from './components/pages/tableauViews/TableauViews';
import Pages from './components/layout/Pages'

const oktaAuth = new OktaAuth(config.oidc);

const publicPages = [
  {
    path: '/login/callback',
    component: LoginCallback,
    layout: Pages,
  },
  {
    path: '/',
    component: LoginCallback,
    layout : Pages,
  },

];

const securePages = [
  {
    path: '/gapreport',
    component: TableauViews,
    layout: Pages,
    viewPath: '/PatientGapReport/PatientGapReport',
    viewHeight: 900,
  },
  {
    path: '/providerlevel',
    component: TableauViews,
    layout: Pages,
    viewPath: '/ProviderLevelPerformance/ProviderLevelPerformance',
    viewHeight: 700,
  },
  {
    path: '/overall',
    component: TableauViews,
    layout: Pages,
    viewPath: '/OverallPerformance/OverallPerformanceDashboard',
    viewHeight: 900,
  },
  {
    path: '/menu',
    component: Menu,
    layout: Pages,
    viewPath: '/OverallPerformance/OverallPerformanceDashboard',
    viewHeight: 1000,
  },

];

const App = () => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
        <Switch>
          <div>
          {securePages.map(
            ({path, viewPath, viewHeight, component: Component, layout : Layout }, index)=> (
              <SecureRoute
              key={index}
              path={path}
              render={props => (
                <Layout {...props}>
                  <Component {...props} viewPath={viewPath} viewHeight={viewHeight} />
                </Layout>
                )}
              />
            )
          )}  
          {publicPages.map(
            (
              {
                path,
                component: Component,
                layout: Layout,
              },
              index
            ) => (
              <Route
                key={index}
                path={path}
                exact
                render={(props) => (
                  <Layout {...props}>
                    <Component
                      {...props}
                    />
                  </Layout>
                )}
              />
            )
          )}
      </div>
      </Switch>
      </div>
    </Security>
  );
};

export default App;
