/* eslint-disable */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
      flexGrow: 1,
      marginTop: 60,
      background: '#f5f7fa',
      padding: theme.spacing(2),
    },
    viewStyling: {
      padding: 40,
      fontFamily: "Arial",
      fontSize: 18,
      width:"130%"
    }
  }));

function WelcomeMessage()  {
  const classes = useStyles();
  return (
      <div className={classes.content}>
        <Paper elevation={3} className={classes.viewStyling}>
        <h2><b>Welcome to the Nebraska Medicine – Primary Care First Dashboard</b></h2>
           <Divider/>
           <br></br>
         <b>Purpose</b>: This site is used to review data associated with the Advance Care Plan measure. The Advance Care Plan measure is a reporting requirement for the CMS Primary Care First program. This site will be available for the next 60-days. Please provide any concerns or feedback directly to the assigned contact person. 
         <br/><b>Contact Person</b>: <b><a href="mailto:awolken@nebraskamed.com" style={{textDecoration:"underline", color:"blue"}} >Angela Wolken</a></b>,  Quality Support Coordinator Population Health
        </Paper>
      </div> 
  )
};
export default WelcomeMessage;