/* eslint-disable */
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { useOktaAuth } from '@okta/okta-react';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import "./index.css"
import Maintenance from '../../alerts/Maintenance';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 30,
    background: '#f5f7fa',
    padding: theme.spacing(2),
  
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  welcomeBlock: {
    flexGrow: 1,
    marginTop: 30,
    background: '#f5f7fa',
    padding: theme.spacing(0),
  },
  viewStyling: {
    padding: 30,
    fontFamily: "Arial",
    fontSize: 15,
    width:"100%",
 
  }
}));

function Menu(){
  const classes = useStyles();
    const { authState, oktaAuth } = useOktaAuth();
    const [open, setOpen] = React.useState(true);


    return (
<>
<div className={classes.content}>
      <div className={classes.toolbar}>
        <div className={classes.welcomeBlock}>
  
      <div> {authState.isAuthenticated && (
        <Paper elevation={2} className={classes.viewStyling}>
           <h2><b>Welcome to the Nebraska Medicine – Primary Care First Dashboard</b></h2>
           <Divider/>
           <br></br>
         <b>Purpose</b>: This site is used to review data associated with the Advance Care Plan measure. The Advance Care Plan measure is a reporting requirement for the CMS Primary Care First program. This site will be available for the next 60-days. Please provide any concerns or feedback directly to the assigned contact person. 
         <br/><b>Contact Person</b>: <b><a href="mailto:awolken@nebraskamed.com" style={{textDecoration:"underline", color:"blue"}} >Angela Wolken</a></b>,  Quality Support Coordinator Population Health


        </Paper>
     )}</div>


    
        <div className="row">
        <div className="col-md-4">
            <a href="/gapreport"><div className="box" >
                <div className="our-services wrapper" >
                  <AssignmentIndIcon></AssignmentIndIcon>
                    <h4> Patients Gap reports</h4>
                </div>
            </div></a>
        </div>

        <div className="col-md-4">
            <a href="/providerlevel"><div className="box">
                <div className="our-services wrapper">
                  <LocalHospitalIcon></LocalHospitalIcon>
                    <h4> Provider Level Performance</h4>
                </div>
            </div></a>
        </div>

        <div className="col-md-4">
            <a href="/overall"> <div className="box">
                <div className="our-services wrapper">
                  <EqualizerIcon></EqualizerIcon>
                    <h4> Overall Performance</h4>
                </div>
            </div> </a>
        </div>
     </div>

  </div>
</div>
</div>
      </>
    );
  }


export default Menu;
